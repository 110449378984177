<template>
  <div>
    <v-dialog
      v-model="addUnitDialogue"
      max-width="500px"
      transition="dialog-transition"
      persistent
    >
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card>
            <v-layout wrap justify-center pa-4>
              <v-flex xs12>
                <v-layout wrap justify-center pb-4>
                  <v-flex xs12>
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 20px;
                        color: #000000;
                      "
                      >Add New Stock</span
                    >
                  </v-flex>
                </v-layout>

                <v-layout wrap justify-start>
                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> Quantity </span>
                    <v-select
                      outlined
                      dense
                      color="#FF1313"
                      placeholder="Quantity"
                      v-model="stockObject.sizename"
                      :items="productSizes"
                      item-value="name"
                      item-text="name"
                      item-color="#FF1313"
                      hide-details
                      class="text-left"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> Price </span>
                    <v-text-field
                      v-model="stockObject.price"
                      type="number"
                      color="#FF1313"
                      hide-selected
                      outlined
                      placeholder="Available Price"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> Stock </span>
                    <v-text-field
                      v-model="stockObject.stock"
                      type="number"
                      color="#FF1313"
                      hide-selected
                      outlined
                      placeholder="Available Stock"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-end py-4>
                  <v-flex xs6 sm4 px-2>
                    <v-btn
                      block
                      tile
                      color="#757575"
                      outlined
                      :ripple="false"
                      depressed
                      @click="addUnitDialogue = false"
                    >
                      <span
                        style="
                          font-family: kumbhsansregular;
                          font-size: 16px;
                          text-transform: none;
                          color: #000000;
                        "
                      >
                        Cancel
                      </span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs6 sm4 px-2>
                    <!-- <v-btn
                      v-if="!addSizeDialogue"
                      tile
                      block
                      dark
                      color="#FF1313"
                      depressed
                      class="text-capitalize"
                      :ripple="false"
                      @click="addStock"
                    >
                      Apply
                    </v-btn> -->
                    <v-btn
                      v-if="addSizeDialogue"
                      tile
                      block
                      dark
                      color="#FF1313"
                      depressed
                      class="text-capitalize"
                      :ripple="false"
                      @click="addSizeStock"
                    >
                      Apply
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
    <!-- hii{{ productStock }} -->
    <v-layout wrap justify-start v-if="sizeData">
      <template v-for="(product, i) in sizeData">
        <v-flex xs12 md6 pa-2 :key="i" style="font-family:poppinsregular">
          <v-card outlined>
            <v-layout wrap justify-start pa-2>
              <v-flex xs12>
                <span class="prodTitle">{{ product.type }}</span>
              </v-flex>
              <v-flex xs12 lg4 align-self-center text-left v-if="product.price">
                <span class="prodSub">Price</span> <br />
                <span>{{ product.price }}</span>
              </v-flex>
              <v-flex xs12 lg4 align-self-center text-left v-if="product.stock">
                <span class="prodSub">Total Stock</span> <br />
                <span>{{ product.stock }}</span>
              </v-flex>
              <v-flex
                xs12
                lg4
                align-self-center
                text-left
                v-if="product.weight"
              >
                <span class="prodSub">Weight</span> <br />
                <span>{{ product.weight }}</span>
              </v-flex>
            </v-layout>
            <v-layout pa-3 wrap justify-center>
              <v-flex xs12 pa-2>
                <v-card flat>
                  <v-layout wrap justify-center fill-height v-if="product.size">
                    <v-flex xs12 py-2 v-if="product.size.length>0">
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs12 align-self-center v-if="product.size">
                      <v-layout wrap>
                        <v-flex
                          xs12
                          lg12
                          v-for="(subs, k) in product.size"
                          :key="k"
                          pa-2
                        >
                          <v-card outlined>
                            <v-layout wrap pa-2>
                              <v-flex xs12 lg12 align-self-center text-left>
                                <span class="prodMain">{{
                                  subs.sizename
                                }}</span>
                              </v-flex>
                              <v-flex xs12 lg4 align-self-center text-left>
                                <span class="prodSub">Price</span> <br />
                                <span>{{ subs.price }}</span>
                              </v-flex>
                              <v-flex xs12 lg4 align-self-center text-left>
                                <span class="prodSub">Stock</span> <br />
                                <span>{{ subs.stock }}</span>
                              </v-flex>
                              <v-flex xs12 lg4 align-self-center text-left>
                                <span class="prodSub">Weight</span> <br />
                                <span>{{ subs.weight }}</span>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </template>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["storage", "sizeData"],
  data() {
    return {
      stockObject: {
        sizename: null,
        price: null,
        stock: null,
      },
      stockObject2: {
        sizename: null,
        price: null,
        stock: null,
      },
      addUnitDialogue: false,
      editDialogue: false,
      addSizeDialogue: false,
      newSize: null,
      newPrice: null,
      newStock: 0,
      colorStatus: false,
      newColor: null,
      // productStock: [],
      // units:[],
      selectedStock: {},
      newType: null,
      addProductDialogue: false,
      productType: null,
      productTypes: [],
      productSizes: [],
      productSizeArray: [],
    };
  },
  computed: {
    units() {
      if (this.storage) {
        //  var units={}
        //   for (var i = 0; i < this.storage.length; i++) {
        //   var obj = {};
        //   obj.value = this.storage[i];
        //   obj.disabled = false;
        //   units.push(obj);
        // }
        return this.storage;
      } else return {};
    },
    productStock() {
      if (this.sizeData.length > 0) return this.sizeData;
      else return [];
    },
  },
  mounted() {
    this.getTypeList();
    this.getSizeList();
  },
  watch: {
    // productStock: {
    //   handler: function () {
    //     this.$emit("stepper", {
    //       productStock: this.productStock,
    //       type: "edit",
    //     });
    //   },
    //   deep: true,
    // },
  },

  methods: {
    getTypeList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/producttype/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // if (response.data.package) this.pricing = response.data.package;
          if (response.data.status) {
            this.productTypes = response.data.data;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSizeList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/productsize/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.productSizes = response.data.data;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editStock() {
      for (var i = 0; i < this.productStock.length; i++) {
        for (var j = 0; j < this.productStock[i].size.length; j++) {
          if (this.productStock[i].size[j]._id == this.stockObject2._id) {
            this.productStock[i].size[j] = JSON.parse(
              JSON.stringify(this.stockObject2)
            );
          }
        }
      }
      this.$emit("stepper", {
        productStock: this.productStock,
      });
      this.editDialogue = false;
    },
    stockAdder(item) {
      if (item == "new") {
        this.addSizeDialogue = true;
      } else {
        this.addSizeDialogue = false;
        this.selectedStock = item;
      }
      this.addProductDialogue = true;
    },

    addSizeStock() {
      this.productSizeArray.push(JSON.parse(JSON.stringify(this.stockObject)));
      // this.productStock.push(JSON.parse(JSON.stringify(this.stockObject)));
      // this.$emit("stepper", {
      //   productStock: this.productStock,
      // });
      this.addUnitDialogue = false;
    },
    addFinalStock() {
      this.productStock.push({
        type: this.productType,
        size: this.productSizeArray,
      });
      this.productType = null;
      this.productSizeArray = [];
      this.$emit("stepper", {
        productStock: this.productStock,
      });
      this.addUnitDialogue = false;
      this.addProductDialogue = false;
    },
  },
};
</script>
<style >
.stockSizeSelector.v-text-field.v-text-field--solo .v-input__control {
  min-height: 50px !important;
  padding: 0 !important;
  border-radius: 5px !important;
}
</style>